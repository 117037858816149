/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import '../../@core/scss/base/bootstrap-extended/variables';
@import '../../@core/scss/base/components/variables-dark';

$fc-sidebar-width: 18rem;

// FONTS
h1, h2, h3, h4, h5, h6 {
	color: $black;
}

.ms-32 {
	margin-left: 32px;
}

.default-page-title {
	font-size: 28px;
	color: $black;
}

.text-heading {
	color: $headings-color;
}

.dark-layout {
	.text-heading {
		color: $theme-dark-headings-color;
	}
}

.muted-paragraph {
	background: $input-disabled-bg;
	border-radius: 5px;
	padding: 16px;
	color: $headings-color;
	margin: 0;
	margin-right: 16px;
	margin-left: 40px;
	cursor: not-allowed;

	min-height: 210px;

	&.muted-paragraph-fit-content {
		min-height: unset;
	}
}

.body-text {
	color: $body-color;
}

@media(max-width: 991px) {
	.default-page-title {
		font-size: 21px;
	}
}

// SPACEMENTS
.mb-025 {
	margin-bottom: 0.25rem;
}

.mb-05 {
	margin-bottom: 0.5rem;
}

.mb-32 {
	margin-bottom: 2rem;
}

.mr-05 {
	margin-right: 0.5rem;
}

.mr-1 {
	margin-right: 1rem;
}

.mr-2 {
	margin-right: 1.5rem;
}

.mr-3 {
	margin-right: 3rem;
}

.ml-05 {
	margin-left: 0.5rem;
}

.ml-1 {	
	margin-left: 1rem;
}

.ml-2 {	
	margin-left: 1.5rem;
}

.ml-3 {	
	margin-left: 3rem;
}

.mt-32 {
	margin-top: 2rem;
}

.pb-05 {
	padding-bottom: 0.5rem;
}

.pr-2 {
	padding-right: 1.5rem;
}

.px-32 {
	padding-left: 2rem;
	padding-right: 2rem;
}

.py-32 {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.p-32 {
	padding: 2rem;
}

.phone-row {
	margin: 0 -4px;

	.col-lg-2, .col-lg-10 {
		padding: 0 4px;
	}

	.col-lg-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}

	.col-lg-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}
}

// IMAGES
.img-contain {
	object-fit: contain;
}

// CONTAINERS

.form-wrapper {
	height: 585px;
	max-width: 377px;
	width: 100%;
	margin: 0 auto;
}

.register-form-wrapper {
	.btn {
	  height: 46px;
	}
}

.auth-alternatives-wrapper {
	display: flex;
    flex-direction: column;
    justify-content: center;
}

.background-auth {
	background: url('../../assets/images/image-login.png') center center / cover no-repeat;
}

.default-page-section {
	padding-top: 48px;
}

@media(max-width: 991px) {
	.auth-alternatives-wrapper {
		height: 100%;
	}
}

// COMPONENTS

.default-scroll-container {
    overflow: auto;

    padding-right: 8px;

    // Firefox scrollbar
    scrollbar-width: thin;
    scrollbar-color: $text-muted transparent;
	
    &::-webkit-scrollbar {
        width: 4px;
    }
    
    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: $text-muted;
        border-radius: 10px;
    }
}

.swal2-title {
	margin: 0 !important;
	padding: 0 32px !important;
	margin-bottom: 6px !important;
	color: $headings-color !important;
	font-size: 21px !important;
	font-weight: 500 !important;
}

.swal2-html-container {
	margin: 0 !important;
	padding: 0 32px !important;
	margin-bottom: 4px !important;
	color: $body-color !important;
	font-size: 14px !important;
}

.swal2-icon.swal2-warning {
	border-color: $red !important;
    color: $red !important;
	margin-bottom: 18px !important;
}

.swal2-icon.swal2-success {
	border: none;
}

.swal2-actions {
	flex-direction: row-reverse;
}

.role-tag {
	font-weight: 600;
	font-size: 12px;
	border-radius: 17px;
	padding: 0 8px;
	width: fit-content;
	
	&.others {
		color: $purple;
		background-color: rgba($purple, .12);
	}

	&.doctor {
		color: $green;
		background-color: rgba($green, .12);
	}
}

.horizontal-divider {
	height: 80%;
	width: 1px;
	background-color: $border-color;
	margin: 0 12px;
}

.dark-layout {
	.horizontal-divider {
		background-color: $theme-dark-border-color;
	}
}

.back-stage {
	display: inline-block;
	color: $secondary;
	transition: .2s;
    background: none;
    border: none;
    outline: none;
    transition: .3s;
    padding: 0;

    &:hover {
        opacity: .6;
    }
}

.form-control {
	font-size: 12px;
	height: 38px;
}

.form-control-sm {
	height: 30px;
}

textarea.form-control {
	height: unset;
}

.form-label {
	color: $headings-color;
}

.default-mobile-sidebar {
    position: static;
	height: 100%;
	background-color: $white;
}

.default-mobile-sidebar-btn {
	display: none;
}

.dark-layout {
	.default-mobile-sidebar {
		background-color: $theme-dark-card-bg;
	}
}

.hidden-dropzone {
	border: 2px dashed transparent;
	padding: 8px;
	transition: .2s;

	&.active {
		background: rgba($primary, 0.12);
		transition: .2s;
	}
}

.default-dropzone {
	transition: .2s;

	&.no-click-dropzone {
		cursor: default !important;
	}

	&.dropzone {
		border-radius: 0;
		border-color: $purple;
		border-width: 2px;
		background: transparent;

		h4 {
			font-weight: 500;
			font-size: 18px;
			color: $purple;
			margin-bottom: 8px;
			text-align: center;
		}
		p {
			font-weight: 400;
			font-size: 14px;
			text-align: center;
			color: $purple;
			margin: 0;
		}
	}

	&.focused {
		background: rgba($primary, 0.12);
		transition: .2s;
	}
}

.btn-icon {
	border: none;
	background: none;
	padding: 0;
}

.btn-sm {
	font-size: 11px !important;
	font-weight: 500 !important;
	height: 30px !important;
}

.text-btn {
	border: none;
	background: none;
	padding: 0;
	transition: .2s;
	color: $body-color;

	&:hover {
		color: $primary !important;
		transition: .2s;
	}

	&.text-danger {
		&:hover {
			opacity: .6;
		}
	}

	&.text-primary {
		&:hover {
			color: $body-color !important;
		}
	}
}

.dark-layout {
	.text-btn {
		color: $theme-dark-headings-color;
	}
}

.file-icon-sm {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: $body-bg;

    display: flex;
    align-items: center;
    justify-content: center;
}

.dark-layout {
	.file-icon-sm {
		background-color: $theme-dark-pagination-bg;
	}
}

.Toastify__toast-body {
	align-items: flex-start;
}

.custom-multi-select {
	.select__multi-value {
		display: none;
	}
}

.sidebar-wrapper {
	position: relative;
	height: 100%;
}

.clinic-alert {
	margin-bottom: -24px !important;

	&:hover {
		span {
			text-decoration: underline;
		}
	}
}

.selected-files-container {
    min-height: 300px;

    .file-icon {
        background-color: $body-bg;
        border-radius: 4px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
    }

    p {
        font-weight: 400;
        font-size: 12px;
        color: $body-color;
        margin: 0;
    }
}

.dark-layout {
    .selected-files-container {
        .file-icon {
            background-color: $theme-dark-table-header-bg;
        }
        p {
            color: $theme-dark-headings-color;
        }
    }
}

.search-input {
	position: relative;

	input {
		padding-left: 48px;
	}

    .search-input-icon {
        display: block;
      z-index: 2;
      position: absolute;
      left: 1.5rem;
      top: 20%;
      cursor: pointer;

      i,
      svg {
        height: 1.25rem;
        width: 1.25rem;
        font-size: 1.25rem;
      }
    }

    .search-input-close {
      z-index: 1;
      display: none;
      position: absolute;
      right: 1rem;
      top: 25%;
      cursor: pointer;

      i,
      svg {
        height: 1.25rem;
        width: 1.25rem;
        font-size: 1.25rem;
      }
    }

	&.open {
		.search-input-close {
			display: block;
		}
	}
}

.search-form {
	padding-left: 52px;
	background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.3344 13.168L18.0844 16.918C18.4177 17.2513 18.4177 17.7513 18.0844 18.0846C17.9177 18.2513 17.6677 18.3346 17.5011 18.3346C17.3344 18.3346 17.0844 18.2513 16.9177 18.0846L13.1677 14.3346C11.9177 15.2513 10.4177 15.8346 8.75106 15.8346C4.83439 15.8346 1.66772 12.668 1.66772 8.7513C1.66772 4.83464 4.83439 1.66797 8.75106 1.66797C12.6677 1.66797 15.8344 4.83464 15.8344 8.7513C15.8344 10.418 15.2511 12.0013 14.3344 13.168ZM8.75106 3.33464C5.75106 3.33464 3.33439 5.7513 3.33439 8.7513C3.33439 11.7513 5.75106 14.168 8.75106 14.168C10.2511 14.168 11.5844 13.5846 12.5844 12.5846C13.5844 11.5846 14.1677 10.2513 14.1677 8.7513C14.1677 5.7513 11.7511 3.33464 8.75106 3.33464Z' fill='black'/%3E%3Cmask id='mask0_2642_36907' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='1' y='1' width='18' height='18'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.3344 13.168L18.0844 16.918C18.4177 17.2513 18.4177 17.7513 18.0844 18.0846C17.9177 18.2513 17.6677 18.3346 17.5011 18.3346C17.3344 18.3346 17.0844 18.2513 16.9177 18.0846L13.1677 14.3346C11.9177 15.2513 10.4177 15.8346 8.75106 15.8346C4.83439 15.8346 1.66772 12.668 1.66772 8.7513C1.66772 4.83464 4.83439 1.66797 8.75106 1.66797C12.6677 1.66797 15.8344 4.83464 15.8344 8.7513C15.8344 10.418 15.2511 12.0013 14.3344 13.168ZM8.75106 3.33464C5.75106 3.33464 3.33439 5.7513 3.33439 8.7513C3.33439 11.7513 5.75106 14.168 8.75106 14.168C10.2511 14.168 11.5844 13.5846 12.5844 12.5846C13.5844 11.5846 14.1677 10.2513 14.1677 8.7513C14.1677 5.7513 11.7511 3.33464 8.75106 3.33464Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_2642_36907)'%3E%3Crect x='0.00109863' width='20' height='20' fill='%236E6B7B'/%3E%3C/g%3E%3C/svg%3E%0A");
	background-position-x: 21px;
	background-position-y: 8px;
	background-repeat: no-repeat;

	.search-input-close,
	.search-input-icon {
		display: none;
	}

	&.open {
		.search-input-close,
		.search-input-icon {
			display: block;
		}
	}
}

 /* dropdown */
 .dropdown-menu {
    .dropdown-item {
      &:hover,
      &:focus,
      &:active,
      &.active {
        color: $white;
        background-color: $primary;

		&.text-danger,
		&.text-success {
			color: $white !important;
			svg {
				stroke: $white !important;
			}
		}
      }
    }
}

.dark-layout {
	.dropdown-menu {
	   .dropdown-item {
		 &:hover,
		 &:focus,
		 &:active,
		 &.active {
		   &.text-danger,
			&.text-success {
				color: $primary !important;
				svg {
					stroke: $primary !important;
				}
			}
		 }
	   }
   }
}

.dropdown-divider {
	margin: 0 !important;
}

@media(max-width: 991.9px) {
	.default-mobile-sidebar {
		position: absolute;
		left: calc(-#{$fc-sidebar-width} - 1.2rem);
		width: $fc-sidebar-width !important;
		flex-basis: $fc-sidebar-width;
		padding: 0;
		z-index: 5;
		transition: all 0.2s, background 0s, border 0s;
		
		&.show {
		  left: 0;
		}
	}

	.default-mobile-sidebar-btn {
		display: block;
		cursor: pointer;
	}

	.default-mobile-card-content {
		min-height: 600px;
	}

}

@media(max-width: 576px) {
	.btn-mobile {
		display: block;
		width: 100%;
	}
}

.dark-layout {
	.swal2-popup {
	  background-color: $theme-dark-card-bg !important;

	  h2,
	  .swal2-html-container {
		color: $theme-dark-headings-color !important;
	  }
	}
}